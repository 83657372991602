import api from '@/plugins/api'
import { integration as endpoint } from '@/configs/endpoints'
import requestValidation from '@/configs/requestValidation'
import { PAGE_SIZE_API } from '@/configs/constants'

const store = 'integration'

export default {
  state: () => ({
    configs: []
  }),
  actions: {
    async getIntegration (context) {
      const response = await api.get(endpoint.configs)

      if (requestValidation(response))
        context.commit('SET_DATA', { store, state: 'configs', data: response.data })
    },

    async updateIntegration (context, body) {
      const response = await api.patch(endpoint.configs, { body })
      const isValid = requestValidation(response)

      if (isValid)
        context.commit('SET_DATA', { store, state: 'configs', data: response.data })

      return await isValid ? response.data : {}
    },

    async testUsConnection (context, body) {
      const response = await api.post(endpoint.testUS, { body })
      return await requestValidation(response) ? response.data : {}
    },

    async createApiToken (context, body) {
      const response = await api.post(endpoint.token(), { body })
      await requestValidation(response)
      return response.data
    },
    async getApiTokens (context, params = {}) {

      params.page_size = PAGE_SIZE_API
      let getParams = params && Object.keys(params)
        .reduce((r, x) => (r + x + '=' + params[x] + '&'), '').slice(0, -1)

      const response = await api.get(`${ endpoint.token() }${ getParams ? `?${ getParams }` : '' } `)
      await requestValidation(response)
      return response.data
    },
    async deleteApiToken (context, id) {
      const response = await api.delete(endpoint.token(id))
      return await requestValidation(response)
    },
    async getApiLink () {
      const response = await api.get(endpoint.apiLink)
      if (await requestValidation(response, { isViewNotify: false }))
        return response.data
    }
  }
}
