export default [
  {
    path: '/presets',
    name: 'preset',
    meta: {
      access: ['view_configonutemplate', 'add_configonutemplate', 'change_configonutemplate', 'delete_configonutemplate']
    },
    component: () => import('@/views/Preset/Preset'),
    children: [
      {
        path: 'preset/new',
        name: 'preset-new',
        meta: {
          access: 'add_configonutemplate'
        },
        component: () => import('@/views/Preset/Form')
      },
      {
        path: 'preset/:id',
        name: 'preset-page',
        meta: {
          access: ['view_configonutemplate', 'add_configonutemplate', 'change_configonutemplate', 'delete_configonutemplate']
        },
        redirect: '/preset/:id/info'
      },
      {
        path: 'preset/:id/info',
        name: 'preset-info',
        meta: {
          access: ['view_configonutemplate', 'add_configonutemplate', 'change_configonutemplate', 'delete_configonutemplate']
        },
        component: () => import('@/views/Preset/Info')
      },
      {
        path: 'preset/:id/edit',
        name: 'preset-edit',
        meta: {
          access: 'change_configonutemplate'
        },
        component: () => import('@/views/Preset/Form')
      }
    ]
  },
  {
    path: '/actions',
    name: 'action',
    meta: {
      access: ['view_configonutemplate', 'add_configonutemplate', 'change_configonutemplate', 'delete_configonutemplate']
    },
    component: () => import('@/views/Preset/Preset'),
    children: [
      {
        path: 'action/new',
        name: 'action-new',
        meta: {
          access: 'add_configonutemplate'
        },
        component: () => import('@/views/Preset/Form')
      },
      {
        path: 'action/:id',
        name: 'action-page',
        meta: {
          access: ['view_configonutemplate', 'add_configonutemplate', 'change_configonutemplate', 'delete_configonutemplate']
        },
        redirect: '/action/:id/info'
      },
      {
        path: 'action/:id/info',
        name: 'action-info',
        meta: {
          access: ['view_configonutemplate', 'add_configonutemplate', 'change_configonutemplate', 'delete_configonutemplate']
        },
        component: () => import('@/views/Preset/Info')
      },
      {
        path: 'action/:id/edit',
        name: 'action-edit',
        meta: {
          access: 'change_configonutemplate'
        },
        component: () => import('@/views/Preset/Form')
      }
    ]
  }
]
