import Vue from 'vue'
import Vuex from 'vuex'

import mutations from '@/store/mutations'

import App from '@/store/modules/app'
import Olt from '@/store/modules/olt'
import Switch from '@/store/modules/switch'
import Onu from '@/store/modules/onu'
import Preset from '@/store/modules/preset'
import PeriodicTask from '@/store/modules/periodicTask'
import Profile from '@/store/modules/profile'
import Directory from '@/store/modules/directory'
import Dashboard from '@/store/modules/dashboard'
import User from '@/store/modules/user'
import Access from '@/store/modules/access'
import Migration from '@/store/modules/migration'
import onuDuplicate from '@/store/modules/onuDuplicate'
import Report from '@/store/modules/report'
import License from '@/store/modules/license'
import Log from '@/store/modules/log'
import Buildings from '@/store/modules/buildings'
import Integration from '@/store/modules/integration'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app: App,
    olt: Olt,
    switch: Switch,
    onu: Onu,
    preset: Preset,
    periodicTask: PeriodicTask,
    profile: Profile,
    directory: Directory,
    dashboard: Dashboard,
    user: User,
    access: Access,
    onuDuplicate: onuDuplicate,
    migration: Migration,
    report: Report,
    license: License,
    log: Log,
    buildings: Buildings,
    integration: Integration
  },
  mutations,
  actions: {
    async loadDataOnLogin ({ dispatch }) {
      await Promise.allSettled([
        dispatch('getAllOlt', { isAll: true }),
        dispatch('getLicense'),
        dispatch('getPresets'),
        dispatch('getMacros'),
        dispatch('getDirectories')
      ])
    }
  }
})

export default store
