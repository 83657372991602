export default [
  {
    path: '/integration',
    name: 'integration',
    meta: {
      access: ['view_accountlog'],
      prevRoutes: ['allRoutes']
    },
    component: () => import('@/views/Integration/Integration')
  }
]
