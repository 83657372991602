const apiVersion = 'api/v1'

export const auth = {
  totp: {
    create: '/api/accounts/totp/create/',
    verify: '/api/accounts/totp/verify/',
    login: '/api/accounts/totp/login/'
  },
  u2f: {
    registration: {
      options: '',
      registration: ''
    },
    authorization: {
      options: `${ apiVersion }/accounts/fido2/begin/`,
      authorization: `${ apiVersion }/accounts/fido2/complete/`
    }
  },
  general: {
    login: `${ apiVersion }/accounts/login/`
  },
  phone: {
    login: ''
  },
  googleAuth: {
    convertToken: '',
    gsiLogin: `${ apiVersion }/accounts/google_gsi/login/`
  }
}

export const integration = {
  configs: `${ apiVersion }/core/config/`,
  testUS: `${ apiVersion }/integrations/userside/test_connection/`,
  token: id => `${ apiVersion }/accounts/api_keys/${ id ? `${ id }/` : '' }`,
  apiLink: `${ apiVersion }/core/public_api_documentation_url/`
}

export const buildings = {
  refreshStatus: `${ apiVersion }/onu_module/onu/refresh_status_by_area/`,
  map: id => `${ apiVersion }/buildings/map_buildings/${ id ? `${ id }/` : '' }`,
  building: id => `${ apiVersion }/buildings/buildings/${ id ? `${ id }/` : '' }`
}

export const profile = {
  log: id => `${ apiVersion }/accounts/user_log/${ id ? `${ id }/` : '' }`,
  me: `${ apiVersion }/accounts/me/`,
  permissions: `${ apiVersion }/accounts/me/permissions/`,
  changePassword: `${ apiVersion }/accounts/change_password/`,
  savedFilters: id => `${ apiVersion }/accounts/saved_filters/${ id ? `${ id }/` : '' }`,
  sessions: id => `${ apiVersion }/token/token/${ id ? `${ id }/` : '' }`,
  avatar: id => `${ apiVersion }/accounts/avatar/${ id ? `${ id }/` : '' }`
}

export const directories = [
  { endpoint: `${ apiVersion }/directory/type_olt/`, state: 'typeOlt' },
  { endpoint: `${ apiVersion }/directory/onu_type/`, state: 'typeOnu' },
  { endpoint: `${ apiVersion }/directory/onu_real_type/`, state: 'realTypeOnu' },
  { endpoint: `${ apiVersion }/directory/content_type/`, state: 'contentType' }
]

export const olt = {
  olt: `${ apiVersion }/olt_module/olt/`,
  action: id => `${ apiVersion }/olt_module/olt/${ id }/`,
  refreshInfo: id => `${ apiVersion }/olt_module/olt/${ id }/synchronize/`,
  task: `${ apiVersion }/core/celery_task/`,
  syncAllOnu: id => `${ apiVersion }/olt_module/olt/${ id }/sync_all_onus/`,
  syncOnuTask: id => `${ apiVersion }/olt_module/sync_onu_task_info/${ id }/`,
  syncOnuTaskCancel: id => `${ apiVersion }/olt_module/sync_onu_task_cancel/${ id }/`,
  checkConnectionNew: `${ apiVersion }/olt_module/olt/check_connection_new_olt/`,
  checkConnection: id => `${ apiVersion }/olt_module/olt/${ id }/check_connection/`,
  oltPort: ({ id, slot, port }) => `${ apiVersion }/olt_module/olt/${ id }/slot/${ slot }/port/${ port }/`,
  generateExcel: `${ apiVersion }/olt_module/olt/xlsx/`
}

export const migration = {
  portMigration: `${ apiVersion }/olt_module/migration_port_onu/`,
  portMigrationHistory: id => `${ apiVersion }/olt_module/migration_port_onu/${ id }/onus_status/`
}

export const onu = {
  transferData: `${ apiVersion }/onu_module/onu/transfer_data/`,
  onu: `${ apiVersion }/onu_module/onu/`,
  firmwareVariety: `${ apiVersion }/onu_module/firmware_variety/`,
  firmwareVarietyAll: `${ apiVersion }/onu_module/firmware_variety/versions_list/`,
  realType: id => `${ apiVersion }/directory/onu_real_type/${ id ? id + '/' : '' }`,
  vendor: id => `${ apiVersion }/directory/device_vendor/${ id ? id + '/' : '' }`,
  action: data => data?.id ?
    `${ apiVersion }/onu_module/onu/${ data.id }/` :
    `${ apiVersion }/onu_module/onu/${ data.olt_id }/${ data.slot_number }/${ data.port_number }/${ data.onu_number }/`,
  unregistered: 'ws/unregistered_onus/',
  unregisteredUpdate: `${ apiVersion }/olt_module/olt/update_unregistered_onus/`,
  realTimeOnuInfo: id => `${ apiVersion }/onu_module/onu/${ id }/get_info_from_olt/`,
  updateOnuBlock: (id, block) => `${ apiVersion }/onu_module/onu/${ id }/update_onu_${ block }/`,
  reboot: id => `${ apiVersion }/onu_module/onu/${ id }/reboot/`,
  bindPresetToOnu: `${ apiVersion }/templates/onu_config/`,
  applyConfig: id => `${ apiVersion }/templates/onu_config/${ id }/apply_config/`,
  chartMeasurements: id => `${ apiVersion }/onu_module/measurements/${ id }/`,
  oltConfig: id => `${ apiVersion }/onu_module/onu/${ id }/config_from_olt/`,
  changeEthernetPort: (onu, id) => `${ apiVersion }/onu_module/onu/${ onu }/ethernet_port/${ id }/`,
  task: `${ apiVersion }/core/celery_task/`,
  search: mac => `${ apiVersion }/core/device_search/${ mac }/`,
  generateExcel: `${ apiVersion }/onu_module/onu/xlsx/`,
  runCustomPreset: id => `${ apiVersion }/templates/onu_template/${ id }/run_custom_preset/`
}

export const report = {
  report: `${ apiVersion }/core/report_files/`,
  download: id => `${ apiVersion }/core/report_files/${ id }/download/`
}

export const preset = {
  preset: `${ apiVersion }/templates/onu_template/`,
  presetAction: id => `${ apiVersion }/templates/onu_template/${ id }/`,
  macros: `${ apiVersion }/templates/variables/`,
  macrosAction: id => `${ apiVersion }/templates/variables/${ id }/`
}

export const core = {
  latestRelease: `${ apiVersion }/core/ep_latest_release/`,
  googleClientId: `${ apiVersion }/core/google_client_id/`
}

export const task = {
  task: `${ apiVersion }/olt_module/beat_schedule/`,
  action: id => `${ apiVersion }/olt_module/beat_schedule/${ id }/`,
  generateExcel: `${ apiVersion }/olt_module/beat_schedule/xlsx/`
}

export const license = {
  license: `${ apiVersion }/core/license_status/`
}

export const user = {
  user: `${ apiVersion }/accounts/user/`,
  action: id => `${ apiVersion }/accounts/user/${ id }/`,
  generateExcel: `${ apiVersion }/accounts/user/xlsx/`
}

export const access = {
  group: `${ apiVersion }/accounts/group/`,
  actionGroup: id => `${ apiVersion }/accounts/group/${ id }/`,
  permission: `${ apiVersion }/accounts/permission/`
}

export const switches = {
  switchInfo: `${ apiVersion }/switch_module/switch/`,
  switchAction: id => `${ apiVersion }/switch_module/switch/${ id }/`,
  switchPort: id => `${ apiVersion }/switch_module/switch/${ id }/port/`,
  switchPortAction: (id, port) => `${ apiVersion }/switch_module/switch/${ id }/port/${ port }/`,
  synchronize: id => `${ apiVersion }/switch_module/switch/${ id }/port/synchronize/`,
  generateExcel: `${ apiVersion }/switch_module/switch/xlsx/`
}

export const dashboard = {
  tasksInProgress: `${ apiVersion }/olt_module/tasks_in_progress/`,
  lastOnus: `${ apiVersion }/onu_module/search_history/`,
  onuOnOlt: `${ apiVersion }/graphics/onu_on_olt_metrics/`,
  onuChart: `${ apiVersion }/graphics/onu_dashboard/`,
  totalOnuOnOlt: `${ apiVersion }/graphics/total_onu_on_olts_metrics/`,
  oltBadRequest: `${ apiVersion }/graphics/request_olt_errors/`
}

export const onuDuplicates = {
  onuDuplicates: `${ apiVersion }/onu_module/onu_duplicates/`,
  delete: id => `${ apiVersion }/onu_module/onu/${ id }/`,
  generateExcel: `${ apiVersion }/onu_module/onu_duplicates/xlsx/`
}
