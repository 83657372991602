export const PAGE_SIZE_DASHBOARD = 10
export const PAGE_SIZE_API = 20
export const PAGE_SIZE_OLT = 50
export const PAGE_SIZE_SWITCH = 20
export const PAGE_SIZE_UNREGISTERED_ONU = 50
export const PAGE_SIZE_ONU = 50
export const PAGE_SIZE_USER = 20
export const PAGE_SIZE_ETHERNET_PORTS = 64
export const PAGE_SIZE_TASK = 20
export const PAGE_SIZE_ONU_DUPLICATES = 14
export const PAGE_SIZE_MIGRATION = 20
export const PAGE_SIZE_SESSION = 10
export const PAGE_SIZE_GROUP = 20
export const PAGE_SIZE_BUILDING = 100
export const PAGE_SIZE_MAP_BUILDING = 200
export const PAGE_SIZE_REPORT = 20
export const PAGE_SIZE_PRESET = 100
export const PAGE_SIZE_LOG = 30
