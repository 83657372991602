import api from '@/plugins/api'
import { profile as endpoint } from '@/configs/endpoints'
import requestValidation from '@/configs/requestValidation'
import { PAGE_SIZE_SESSION } from '@/configs/constants'

const store = 'profile'

export default {
  state: () => ({
    user: {},
    permissions: [],
    sessions: [],
    isLoadSessions: false
  }),
  actions: {
    async deleteAvatar (context, id) {
      const response = await api.delete(endpoint.avatar(id))
      context.dispatch('getProfile')
      return await requestValidation(response)
    },

    async setAvatar (context, { user, photo }) {
      let type = photo.match(/[^:/]\w+(?=;|,)/)[0]
      type = type.includes('xml') ? 'svg' : type
      const fileName = `${ Date.now().toString() }.${ type }`

      function DataURIToBlob (dataURI) {
        const splitDataURI = dataURI.split(',')
        const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
        const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

        const ia = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i++)
          ia[i] = byteString.charCodeAt(i)

        return new Blob([ia], { type: mimeString })
      }

      const file = new FormData()
      file.append('user', user || context.state.user.id)
      file.append('photo', DataURIToBlob(photo), fileName)

      const response = await api.post(endpoint.avatar(), { body: file })
      context.dispatch('getProfile')
      return await requestValidation(response)
    },
    async getAvatar (context, id) {
      const response = await api.get(endpoint.avatar(id || context.state.user.id))

      if (await requestValidation(response)) {
        return response.data
      }
    },
    async getProfile (context) {
      const response = await api.get(endpoint.me)
      const responsePermissions = await api.get(endpoint.permissions)

      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'user', data: response.data })
        context.commit('SET_DATA', { store, state: 'permissions', data: responsePermissions.data.permissions })
      }
    },

    async getSessions (context, params = {}) {
      context.commit('SET_DATA', { store, state: 'isLoadSessions', data: true })

      params.page_size = PAGE_SIZE_SESSION
      params.is_api_key = false
      let getParams = params && Object.keys(params)
        .reduce((r, x) => (r + x + '=' + params[x] + '&'), '').slice(0, -1)

      const response = await api.get(`${ endpoint.sessions() }${ getParams ? `?${ getParams }` : '' } `)

      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'sessions', data: response.data })
      }
      context.commit('SET_DATA', { store, state: 'isLoadSessions', data: false })
      return response.data
    },
    async deleteToken (context, id) {
      const response = await api.delete(endpoint.sessions(id))
      return await requestValidation(response)
    },

    async changePassword (context, body) {
      const response = await api.post(endpoint.changePassword, { body })
      return await requestValidation(response)
    },

    async saveFilter (context, body) {
      const response = await api.post(endpoint.savedFilters(), { body })
      return await requestValidation(response)
    },

    async getFilters (context, params = {}) {

      let getParams = params && Object.keys(params)
        .reduce((r, x) => (r + x + '=' + params[x] + '&'), '').slice(0, -1)

      const response = await api.get(`${ endpoint.savedFilters() }${ getParams ? `?${ getParams }` : '' } `)
      await requestValidation(response)
      return response.data
    },

    async deleteFilter (context, id) {
      const response = await api.delete(endpoint.savedFilters(id))
      return await requestValidation(response)
    }
  }
}
