import api from '@/plugins/api.js'
import notify from '@ananasbear/notify'
import requestValidation from '@/configs/requestValidation'
import { PAGE_SIZE_ONU } from '@/configs/constants'
import { onu as endpoint } from '@/configs/endpoints'

const store = 'onu'

let socketUnregisteredOnu = null

export default {
  state: () => ({
    onu: [],
    onuVariety: [],
    isLoadingAllOnu: true,
    isLoadingOnuVariety: true,
    info: {},
    isLoadInfoOnu: true,
    unregistered: [],
    isViewUnregisteredOnu: false,
    isViewConfigOnu: false,
    isOnuEditInConfig: false,
    chartMeasurementsAttenuation: [],
    chartMeasurementsRxTx: [],
    isViewTerminal: false,
    terminalCustomAction: null,
    terminalInfo: {},
    tasks: [],
    isSocketError: true
  }),
  actions: {
    async getFirmwareVarietyAll () {
      const response = await api.get(endpoint.firmwareVarietyAll)
      await requestValidation(response)
      return response.data
    },
    async getVendor (context, id) {
      const response = await api.get(endpoint.vendor(id))
      await requestValidation(response)
      return response.data
    },
    async getRealType (context, id) {
      const response = await api.get(endpoint.realType(id))
      await requestValidation(response)
      return response.data
    },
    async getAllOnu (context, params) {
      context.commit('SET_DATA', { store, state: 'isLoadingAllOnu', data: true })

      params.page_size = PAGE_SIZE_ONU
      let getParams = params && Object.keys(params)
        .reduce((r, x) => (r + x + '=' + params[x] + '&'), '').slice(0, -1)

      const response = await api.get(`${ endpoint.onu }${ getParams ? `?${ getParams }` : '' } `)

      if (await requestValidation(response, params.page === 1)) {
        context.commit('SET_DATA', { store, state: 'onu', data: response.data })
      }
      context.commit('SET_DATA', { store, state: 'isLoadingAllOnu', data: false })
      return response.code
    },
    async getVarietyOnu (context, params) {

      context.commit('SET_DATA', { store, state: 'isLoadingOnuVariety', data: true })

      params.page_size = PAGE_SIZE_ONU
      let getParams = params && Object.keys(params)
        .reduce((r, x) => (r + x + '=' + params[x] + '&'), '').slice(0, -1)

      const response = await api.get(`${ endpoint.firmwareVariety }${ getParams ? `?${ getParams }` : '' } `)

      if (await requestValidation(response, params.page === 1)) {
        context.commit('SET_DATA', { store, state: 'onuVariety', data: response.data })
      }
      context.commit('SET_DATA', { store, state: 'isLoadingOnuVariety', data: false })
      return response.code
    },
    async findOnu (context, data) {
      const response = await api.get(endpoint.search(data.mac_serial))
      if (await requestValidation(response)) return response.data
    },
    async getInfoOnu (context, data) {
      const query = data.params ? `?${ new URLSearchParams(data.params) }` : ''
      const url = data.type === 'id' ? endpoint.action({ id: data.id }) : endpoint.action(data.position)

      context.commit('SET_DATA', { store, state: 'isLoadInfoOnu', data: true })

      const response = await api.get(url + query)

      const isResult = await requestValidation(response)

      if (isResult) {
        context.commit('SET_DATA', { store, state: 'info', data: response.data })
        context.commit('SET_DATA', { store, state: 'isLoadInfoOnu', data: false })
      }
      return response.code
    },
    async getInfoOnuRealTime (context, id) {
      const response = await api.get(endpoint.realTimeOnuInfo(id))

      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'info', data: response.data })
        return true
      } else return false
    },

    async getUnregisteredOnu (context) {
      socketUnregisteredOnu && socketUnregisteredOnu.close(1000, 'restart')

      const url = process.env.VUE_APP_API.includes('http') ?
        process.env.VUE_APP_API.replace('http', 'ws') :
        `${ location.origin.replace('http', 'ws') }/`
      socketUnregisteredOnu = new WebSocket(`${ url }${ endpoint.unregistered }`)

      socketUnregisteredOnu.onopen = () => {
        socketUnregisteredOnu.addEventListener('message', event => {
          const data = JSON.parse(event.data).value
          context.commit('SET_DATA', { store, state: 'unregistered', data })
          context.commit('SET_DATA', { store, state: 'isSocketError', data: false })
        })

        socketUnregisteredOnu.addEventListener('error', function (event) {
          console.error('WS error: ', event)
          context.commit('SET_DATA', { store, state: 'isSocketError', data: true })
          notify.error('notify.unregisteredOnuFailed')
        })
      }
    },

    async updateUnregisteredOnu () {
      return await api.post(endpoint.unregisteredUpdate, { body: {} })
    },

    async rebootOnu (context, id) {
      const response = await api.post(endpoint.reboot(id))
      const isResult = await requestValidation(response)
      if (isResult) {
        await context.dispatch('getInfoOnu', { id, type: 'id' })
      }
      return isResult
    },

    async deleteOnu (context, id) {
      const response = await api.delete(endpoint.action({ id }))
      return await requestValidation(response)
    },

    async bindPresetToOnu (context, body) {
      const response = await api.post(endpoint.bindPresetToOnu, { body })
      if (await requestValidation(response)) {
        return response
      }
    },

    async runCustomPreset (context, data) {
      const { onu_id, input_params, template } = data
      const response = await api.post(endpoint.runCustomPreset(template), { body: { onu_id, input_params } })
      const responseData = response.code === 200
        ? response.data
        : await requestValidation(response, { isViewNotify: false, getData: true })
      if ([200, 400].includes(response.code)) {
        const consoleResult = responseData.data ?
          responseData.data?.preset_output || responseData.data?.detail :
          responseData.preset_output
        return consoleResult || 'command failed'
      }
      return 'command failed'
    },

    async savePresetOnOnu (context, data) {
      const response = await api.post(endpoint.applyConfig(data.body.id), { body: data.body })
      const responseData = response.code === 200
        ? response.data
        : await requestValidation(response, { isViewNotify: false, getData: true })
      const status = response.code === 200
        ? await requestValidation(response, { isViewNotify: true })
        : responseData.state

      if ([200, 400].includes(response.code)) {
        if (data.isViewConfigAfterApply || response.code === 400) {
          const consoleResult = responseData.data?.detail ? responseData.data?.detail : responseData?.detail
          context.commit('SET_DATA', { store, state: 'terminalInfo', data: { onu: data.body.sn, text: consoleResult } })

          const isArray = Array.isArray(responseData?.data)
          if (isArray && responseData?.data.find(el => el.includes('has no available numbers for onus left'))) {
            return { status, data: responseData.data }
          } else context.commit('SET_DATA', { store: 'onu', state: 'isViewTerminal', data: true })
        }
      }
      return { status, id: response?.data?.onu_id }
    },

    async getChartMeasurements (context, params) {
      const state = params.type === 'attenuation' ? 'chartMeasurementsAttenuation' : 'chartMeasurementsRxTx'
      delete params.type
      let getParams = params && Object.keys(params)
        .reduce((r, x) => (r + x + '=' + params[x] + '&'), '').slice(0, -1)

      const response = await api.get(`${ endpoint.chartMeasurements(params.id) }${ getParams ? `?${ getParams }` : '' } `)
      if (await requestValidation(response)) {

        context.commit('SET_DATA', { store, state, data: response.data })
      }
    },
    async simpleUpdateInfo (context, data) {
      const response = await api.patch(endpoint.action(data), { body: data.body })
      context.commit('SET_DATA', { store, state: 'info', data: response.data })

      return await requestValidation(response)
    },
    async changeOnuInfo (context, data) {
      context.commit('SET_DATA', { store, state: 'isLoadInfoOnu', data: true })

      const url = data.type === 'id' ? endpoint.action({ id: data.id }) : endpoint.action(data.position)
      const response = await api.patch(url, { body: data.body })

      const isResult = await requestValidation(response)

      isResult ? await context.dispatch('getInfoOnu', data) : context.commit('SET_DATA', {
        store,
        state: 'isLoadInfoOnu',
        data: false
      })
      return isResult
    },
    async changeEthernetPort (context, data) {
      const response = await api.patch(endpoint.changeEthernetPort(data.onu, data.id), { body: data.body })

      const isResult = await requestValidation(response)
      isResult && context.commit('SET_DATA_PORT', { store, state: 'onu.info.ethernet_ports', data: response.data })
      return isResult
    },
    async transferData (context, body) {
      const response = await api.post(endpoint.transferData, { body })
      return await requestValidation(response)
    },

    async getOnuTasks (context, params) {
      let getParams = new URLSearchParams()
      getParams.append('page', params.page ? String(params.page) : '1')
      getParams.append('onu_id', String(params.onu_id))
      getParams.append('page_size', '20')

      const response = await api.get(`${ endpoint.task }?${ getParams }`)

      const isResult = await requestValidation(response)
      isResult && context.commit('SET_DATA', { store, state: 'tasks', data: response.data })
      return response.code
    },

    async updateOnuInfo (context, [id, blockId]) {
      const response = await api.get(`${ endpoint.updateOnuBlock(id, blockId) }`)
      const isResult = await requestValidation(response)

      if (isResult) {
        context.commit('SET_DATA', { store, state: 'info', data: response.data })
        notify.success(`notify.onu.update.${ blockId }.success`)
      } else {
        notify.error(`notify.onu.update.${ blockId }.success`)
      }

      return response.code
    }

    // async generateOnuReport (context, params) {
    //   const query = new URLSearchParams(params)
    //   return await api.post(`${ endpoint.generateExcel }?${ query }`)
    // }

  }
}
