import notify from '@/plugins/notify'
import { handlerFormValidation } from '@/configs/formValidation.methods'
import store from '@/store'

const defaultDataObject = { isViewNotify: true, getData: false }

export default async (response, data = defaultDataObject) => {

  const { isViewNotify } = data
  switch (response.code) {
    case 200:
    case 201:
    case 204:
      return true

    case 400: {
      const errors = await response.data.json()
      if (isViewNotify) handlerFormValidation(errors)

      return data.getData ? { state: false, data: errors } : false
    }

    case 404:
      isViewNotify && notify.warning('notify.notExist')
      return false

    case 403:
      const errors = await response.data.json()
      if (errors?.detail?.includes('License is not valid')) {
        store.dispatch('toggleBillNotification', {
          isShowPopup: true
        })
      }
      return false

    case 500:
      isViewNotify && notify.error('notify.serverError')
      return false

    case 504:
      isViewNotify && notify.error('notify.requestTimeout')
      return false

    default:
      return false
  }
}
