import api from '@/plugins/api'
import { dashboard as endpoint } from '@/configs/endpoints'
import requestValidation from '@/configs/requestValidation'
// import {PAGE_SIZE_ONU} from '@/configs/constants'

const store = 'dashboard'

export default {
  state: () => ({
    // tasks: [{ id: 1, olt: 'test'}],
    lastOnus: [],
    tasks: [],
    onuChart: [],
    onuOnOlt: [],
    oltBadRequest: [],
    totalOnuOnOlt: [],
    isLoadingLastOnus: false,
    isLoadingTasks: false
  }),
  actions: {
    async getTasksInProgress (context) {
      context.commit('SET_DATA', { store, state: 'isLoadingTasks', data: true })

      const response = await api.get(endpoint.tasksInProgress)
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'tasks', data: response.data })
      }
      context.commit('SET_DATA', { store, state: 'isLoadingTasks', data: false })
    },
    async getOltBadRequestChart (context, params) {
      let getParams = params && Object.keys(params)
        .reduce((r, x) => (r + x + '=' + params[x] + '&'), '').slice(0, -1)
      const response = await api.get(`${ endpoint.oltBadRequest }${ getParams ? `?${ getParams }` : '' } `)
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'oltBadRequest', data: response.data })
      }
    },
    async getOnuOnOltChart (context, params) {
      let getParams = params && Object.keys(params)
        .reduce((r, x) => (r + x + '=' + params[x] + '&'), '').slice(0, -1)

      const response = await api.get(`${ endpoint.onuOnOlt }${ getParams ? `?${ getParams }` : '' } `)
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'onuOnOlt', data: response.data })
      }
    },
    async getTotalOnuOnOltChart (context, params) {
      let getParams = params && Object.keys(params)
        .reduce((r, x) => (r + x + '=' + params[x] + '&'), '').slice(0, -1)

      const response = await api.get(`${ endpoint.totalOnuOnOlt }${ getParams ? `?${ getParams }` : '' } `)

      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'totalOnuOnOlt', data: [] })
        context.commit('SET_DATA', { store, state: 'totalOnuOnOlt', data: response.data })
      }
    },
    async getOnuChart (context) {
      const response = await api.get(endpoint.onuChart)
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'onuChart', data: response.data })
      }
    },
    async getLastOnus (context) {
      context.commit('SET_DATA', { store, state: 'isLoadingLastOnus', data: true })

      const response = await api.get(endpoint.lastOnus)
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'lastOnus', data: response.data })
      }
      context.commit('SET_DATA', { store, state: 'isLoadingLastOnus', data: false })
    }
  }
}
